@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins/breakpoints";

/*============================
		COLOR Cyan
==============================*/

$primary-color: #0097b2;
$primary-color-hover: darken($primary-color, 7%);

::selection {
  background: $primary-color;
}

a,
a:focus {
  color: $primary-color;
}
a:hover,
a:active {
  color: $primary-color-hover;
}

.primary-menu {
  ul.navbar-nav > li {
    &.dropdown {
      .dropdown-menu li {
        &:hover > a:not(.btn) {
          color: $primary-color;
        }
      }
    }
  }
  &.navbar-line-under-text ul.navbar-nav > li {
    > a:not(.btn):after {
      border-color: $primary-color;
    }
  }
}

// For Responsive Navbar
@mixin navbar-responsive {
  ul.navbar-nav {
    li {
      &:hover > a:not(.btn),
      & > a.active:not(.btn) {
        color: $primary-color;
      }
    }
  }
}

.navbar-expand-none {
  @include navbar-responsive;
}

@include media-breakpoint-down(xs) {
  .navbar-expand-sm {
    @include navbar-responsive;
  }
}

@include media-breakpoint-down(sm) {
  .navbar-expand-md {
    @include navbar-responsive;
  }
}

@include media-breakpoint-down(md) {
  .navbar-expand-lg {
    @include navbar-responsive;
  }
}
@include media-breakpoint-down(lg) {
  .navbar-expand-xl {
    @include navbar-responsive;
  }
}

/*== Secondary Nav ==*/
.secondary-nav.nav {
  &.alternate {
    .nav-item .nav-link.active {
      border-color: $primary-color;
    }
  }
}

/* Page Header */
.page-header {
  &.page-header-text-dark {
    .breadcrumb > li {
      a {
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

/* Accordion */
.accordion {
  &:not(.accordion-flush) .accordion-header .accordion-button {
    background-color: $primary-color;
  }
}

/* Nav */

.nav-tabs {
  .nav-item .nav-link {
    &.active {
      &:after {
        background-color: $primary-color;
      }
    }
  }
}
.nav-pills .nav-link:not(.active):hover {
  color: $primary-color;
}

/* Brands Grid */
.brands-grid {
  > .row > div a {
    &:hover {
      color: $primary-color;
    }
  }
}

#footer {
  .nav {
    .nav-item {
      .nav-link {
        &:focus {
          color: $primary-color;
        }
      }
    }
    .nav-link:hover {
      color: $primary-color;
    }
  }
  .footer-copyright {
    .nav {
      .nav-link:hover {
        color: $primary-color;
      }
    }
  }
}

/* Back to Top */
#back-to-top {
  &:hover {
    background-color: $primary-color;
  }
}

/* Extras */
.bg-primary,
.badge-primary {
  background-color: $primary-color !important;
}
.text-primary {
  color: $primary-color !important;
}

.btn-link {
  color: $primary-color;
}

.btn-link:hover {
  color: $primary-color-hover !important;
}

.text-muted {
  color: #8e9a9d !important;
}
.text-light {
  color: #dee3e4 !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: $primary-color-hover !important;
}
.border-primary {
  border-color: $primary-color !important;
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover {
    background-color: $primary-color-hover;
    border-color: $primary-color-hover;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
}

.btn-outline-primary,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: $primary-color;
  border-color: $primary-color;
  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
  }
}

.progress-bar,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary-color;
}
.page-item.active .page-link,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label:before {
  background-color: $primary-color;
  border-color: $primary-color;
}
.list-group-item.active {
  background-color: $primary-color;
  border-color: $primary-color;
}
.page-link {
  color: $primary-color;
  &:hover {
    color: $primary-color-hover;
  }
}

.slick-dots {
  li {
    &.slick-active button {
      border-color: $primary-color;
    }
    &.slick-active button:before,
    button:hover:before {
      background-color: $primary-color;
    }
  }
}
